*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: 'Inter';
}

.profile-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 8px;
  font-weight: bolder;
}
.profile-section .arrow-img{
  width: 50px;
  height: 50px;
  padding: 10px;
  margin-left: 40%;
  border: 1px dotted gray;
}
.profile-section .dotted-img{
  display: none;
}
.profile-section img{
  height: 88px;
  width: 88px;
  border-radius: 50%;
}
.links-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  gap: 8px;
 
}
.links-section a{
  text-decoration: none;
  color: black;
  background-color: #EAECF0;
  width: 80%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: bolder;
  border: none;
}
.links-section a p{
  margin-left: 20px;
  font-size: 0.5rem;
  color: red;
}
.links-section aside img{
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
hr{
  width: 80%;
  margin: 100px auto 5px auto;
}
.footer-section{

  display: flex;
  justify-content: space-evenly;
}
.zuri-img{
  width: 100px;
  height: 20px;
}
.footer-section p{
  color: gray;
}
.footer-section img{
  width: 130x;
  height: 32px;
}
@media screen and (max-width:375px) {
  .profile-section .arrow-img{
    display: none;
  }
  .profile-section .dotted-img{
      display: block;
      width: 50px;
      height: 50px;
      padding: 10px;
      margin-left: 50%;
      border: 1px dotted gray;
  }
  .footer-section{
      flex-direction: column;
      margin-left: 10%;
      gap: 5px;
  }
  .footer-section img{
      width: 130px;
      height: 20px;
  }
}